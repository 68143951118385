import "@rainbow-me/rainbowkit/styles.css";

import React, { useEffect, useState } from "react";
import $BOOM3 from "../../assets/images/$BOOM3.svg";
import downErrow from "../../assets/images/downErrow.svg";

import {
  configureChains,
  createConfig,
  WagmiConfig,
  useWalletClient,
  useAccount,
  useSwitchNetwork,
} from "wagmi";
import {
  ConnectButton,
  RainbowKitProvider,
  darkTheme,
  useAccountModal,
  useConnectModal,
} from "@rainbow-me/rainbowkit";
import { polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { BigNumber, Contract, constants, ethers, utils } from "ethers";
import $BGEM from "../../assets/images/daily/Diamond.svg";
import config, { mainnetConfig } from "../../config";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import imxLogo from "../../assets/images/passport_logo.svg";
import sequenceLogo from "../../assets/images/sequence_logo.png";
import { sequenceWallet } from "@0xsequence/rainbowkit-plugin";
import { PassportProvider, usePassport } from "../../context/PassportProvider";
import { useDispatch } from "react-redux";
import { getHunters, getItems, loaderonoff } from "../../store/Items/actions";
import { useSelector } from "react-redux";
import { sequence } from "0xsequence";
import axios from "axios";
import { GET_BALANCES } from "../../helpers/url.helper";
import { get } from "../../helpers/api.helper";
import BoomIcon from "../../assets/images/$BOOM3.svg";
import BgemIcon from "../../assets/images/daily/Diamond.svg";
const publicRpcUrl = "https://rpc.ankr.com/polygon";
const provider = new ethers.providers.JsonRpcProvider(publicRpcUrl);

const spinnerCss = css`
  display: block;
  margin: 0 auto;
`;

const { chains, publicClient } = configureChains(
  [polygon],
  [
    jsonRpcProvider({
      rpc: () => ({ http: publicRpcUrl }),
    }),
    publicProvider(),
  ]
);
const connectors = connectorsForWallets([
  {
    groupName: "Available",
    wallets: [
      sequenceWallet({
        chains,
        defaultNetwork: "polygon",
        connect: {
          app: "HuntersOnChain",
        },
      }),
    ],
  },
]);
const wagmiConfig = createConfig({
  connectors,
  publicClient,
});

const SwapPage = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { openConnectModal } = useConnectModal();
  const { data: walletClient } = useWalletClient();
  const { openAccountModal } = useAccountModal();
  const { connect, disconnect, address } = usePassport();
  const dispatch = useDispatch();
  const allhunters = useSelector((state) => state.Items.allhunters);
  const allitems = useSelector((state) => state.Items.allitems);
  const [tab, setTab] = useState("hunters");
  const [balances, setBalances] = useState(null);
  
  const getBalances = async () => {
    try {
      const balances = await get(GET_BALANCES(sequenceWallet));
      console.log("BALANCES", balances?.data?.response);
      setBalances(balances?.data?.response);

      return balances?.data?.response;
    } catch (error) {
      console.log(error);
    }
  }

  const { address: sequenceWallet } = useAccount();
  useEffect(() => {
    if (!sequenceWallet) return;

    dispatch(loaderonoff(true));
    let params = {
      common: 1,
      uncommon: 1,
      rare: 1,
      epic: 1,
      legendary: 1,
      isLent: 0,
      isBorrowed: 0,
    };
    dispatch(getHunters(params, sequenceWallet));
    dispatch(getItems(params, sequenceWallet));
    getBalances();
    dispatch(loaderonoff(false));
  }, [sequenceWallet]);

  const verifyBetaTester = async () => {
    const response = await axios.get(
      `https://api.boomland.io/api/v1/bridge/beta-tester/${sequenceWallet}`
    );

    return response?.data?.response?.allowed;
  };

  const transferHunters = async () => {
    try {
      setIsLoading(true);

      const isBetaTester = await verifyBetaTester();
      if (!isBetaTester) {
        setError("This tool is not available yet, stay tunned to our discord!");
        return;
      }

      const ERC1155_INTERFACE = new utils.Interface([
        "function safeBatchTransferFrom(address,address,uint256[],uint256[],bytes) external",
      ]);
      
      const ERC20_INTERFACE = new utils.Interface([
        "function transfer(address,uint256) external returns (bool)",
      ]);

      const wallet = await sequence.getWallet();
      const sequenceSigner = wallet.getSigner(137);

      const hunters = allhunters;
      const studioWallet = "0x764d3a80ae2C1Dc0a38d14787f382168EF0Cd270";

      const HunterContract = new ethers.Contract(
        config.Contract.hunter.address,
        config.Contract.hunter.abi,
        provider
      );

      const message = `Boomland - Bridge Genesis Hunters from Polygon ${sequenceWallet} to Immutable ${address}`;

      const signature = await sequenceSigner.signMessage(message);

      let txs = [];

      const hunterTxs = hunters.map((hunter) => {
        const data = HunterContract.interface.encodeFunctionData(
          "transferFrom",
          [sequenceWallet, studioWallet, hunter.tokenId]
        );
        const tx = { from: sequenceWallet, to: HunterContract.address, data };
        return tx;
      });

      if (hunterTxs.length) txs.push(...hunterTxs);

      // Add BGEM transfer if there's a balance
      if (balances?.bgemBalance && parseFloat(balances.bgemBalance) > 0) {
        console.error(`balances.bgemBalance: ${balances.bgemBalance}`);
        const bgemAmount = ethers.utils.parseUnits(String(balances.bgemBalance), 18);
        const data = ERC20_INTERFACE.encodeFunctionData(
          "transfer",
          [studioWallet, bgemAmount]
        );
        const bgemTx = {
          from: sequenceWallet,
          to: config.Contract.bgem.address,
          data,
        };
        txs.push(bgemTx);
      }

      // Add BOOM transfer if there's a balance
      if (balances?.boomBalance && parseFloat(balances.boomBalance) > 0) {
        // Calculate the amount in multiples of 1000
        const boomWholeAmount = Math.floor(parseFloat(balances.boomBalance) / 1000) * 1000;

        console.error(`boomWholeAmount: ${boomWholeAmount}`);
        
        // Only proceed if there's at least 1000 BOOM
        if (boomWholeAmount >= 1000) {
          const boomAmount = ethers.utils.parseUnits(String(boomWholeAmount), 2);
          const data = ERC20_INTERFACE.encodeFunctionData(
            "transfer",
            [studioWallet, boomAmount]
          );
          const boomTx = {
            from: sequenceWallet,
            to: config.Contract.boom.address,
            data,
          };
          txs.push(boomTx);
        }
      }

      if (allitems.artifacts?.length) {
        const ids = [];
        const amounts = [];

        allitems.artifacts.forEach((artifact) => {
          ids.push(artifact.tokenId);
          amounts.push(artifact.amount);
        });

        const data = ERC1155_INTERFACE.encodeFunctionData(
          "safeBatchTransferFrom",
          [sequenceWallet, studioWallet, ids, amounts, "0x00"]
        );

        const artifactTx = {
          from: sequenceWallet,
          to: config.Contract.artifact.address,
          data,
        };

        txs.push(artifactTx);
      }

      if (allitems.shards?.length) {
        const ids = [];
        const amounts = [];

        allitems.shards.forEach((shard) => {
          ids.push(shard.tokenId);
          amounts.push(shard.amount);
        });

        const data = ERC1155_INTERFACE.encodeFunctionData(
          "safeBatchTransferFrom",
          [sequenceWallet, studioWallet, ids, amounts, "0x00"]
        );

        const shardTx = {
          from: sequenceWallet,
          to: config.Contract.shard.address,
          data,
        };

        txs.push(shardTx);
      }

      if (allitems.equipments?.length) {
        const ids = [];
        const amounts = [];

        allitems.equipments.forEach((equipment) => {
          ids.push(equipment.tokenId);
          amounts.push(equipment.amount);
        });

        const data = ERC1155_INTERFACE.encodeFunctionData(
          "safeBatchTransferFrom",
          [sequenceWallet, studioWallet, ids, amounts, "0x00"]
        );

        const equipmentTx = {
          from: sequenceWallet,
          to: config.Contract.equipment.address,
          data,
        };

        txs.push(equipmentTx);
      }

      const tx = await sequenceSigner.sendTransaction(txs);

      const receipt = await tx.wait(2);

      await axios.post("https://api.boomland.io/api/v1/bridge/start", {
        txHash: receipt.transactionHash,
        signature,
        account: sequenceWallet,
        immutableAddress: address,
      });

      setSuccess(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      setError(e?.message || "Internal Server Error");
    }
  };

  console.log({ balances });
  return (
    <div className="container-x h-full">
      <div className="sortbar">
        <h3>Bridge from Polygon to Immutable</h3>
      </div>
      <span className="border-b" />
      <div className="mt-30 flex flex-col justify-center">
        <div className="h-screen w-full px-2 self-center">
          <div className="flex flex-row justify-center w-full">
            <div className="defi-cards-bg mt-2 sm:w-[480px] sm:h-[320px] flex flex-row flex-wrap overflow-auto mr-10 relative">
              <div className="flex flex-row p-2 rounded-md -top-2 -left-2 absolute w-full">
                <button
                  className={`rounded-l-md p-1 flex-1 ${
                    tab === "hunters" ? "bg-[#551c60]" : "bg-[#7b309a]"
                  }`}
                  onClick={() => setTab("hunters")}
                >
                  Hunters
                </button>
                <button
                  className={`p-1 flex-1 ${
                    tab === "artifacts" ? "bg-[#551c60]" : "bg-[#7b309a]"
                  }`}
                  onClick={() => setTab("artifacts")}
                >
                  Artifacts
                </button>
                <button
                  className={`p-1 flex-1 ${
                    tab === "shards" ? "bg-[#551c60]" : "bg-[#7b309a]"
                  }`}
                  onClick={() => setTab("shards")}
                >
                  Shards
                </button>
                <button
                  className={`p-1 flex-1 ${
                    tab === "equipments" ? "bg-[#551c60]" : "bg-[#7b309a]"
                  }`}
                  onClick={() => setTab("equipments")}
                >
                  Equipments
                </button>
                <button
                  className={`p-1 flex-1 ${
                    tab === "bgem" ? "bg-[#551c60]" : "bg-[#7b309a]"
                  }`}
                  onClick={() => setTab("bgem")}
                >
                  BGEM
                </button>
                <button
                  className={`rounded-r-md p-1 flex-1 ${
                    tab === "boom" ? "bg-[#551c60]" : "bg-[#7b309a]"
                  }`}
                  onClick={() => setTab("boom")}
                >
                  BOOM
                </button>
              </div>
              {sequenceWallet && allhunters?.length && tab === "hunters"
                ? allhunters?.map((hunter, index) => {
                    const imgid = hunter?.details?.generatedName.slice(0, 3);

                    return (
                      <div className="w-[120px] h-[120px] p-1" key={index}>
                        <img
                          className={`rounded-md w-full h-full`}
                          src={`https://hunt-nft.cdn.boombit.cloud/Gifs/${imgid}/${hunter?.details?.generatedName}.gif`}
                        />
                      </div>
                    );
                  })
                : ""}

              {sequenceWallet &&
              allitems?.artifacts?.length &&
              tab === "artifacts"
                ? allitems?.artifacts
                    ?.filter((item) => item.name !== "Unknown")
                    .map((item, index) => {
                      console.log({ item });
                      return (
                        <div className="w-[120px] h-[120px] p-1" key={index}>
                          <img
                            className={`rounded-md w-full h-full`}
                            src={`https://hunt-nft.cdn.boombit.cloud/Perks/a${item.tokenId}.png`}
                          />
                        </div>
                      );
                    })
                : ""}

              {sequenceWallet && allitems?.shards?.length && tab === "shards"
                ? allitems?.shards
                    ?.filter((item) => item.name !== "Unknown")
                    .map((item, index) => {
                      console.log({ item });
                      return (
                        <div className="w-[120px] h-[120px] p-1" key={index}>
                          <img
                            className={`rounded-md w-full h-full`}
                            src={`https://hunt-nft.cdn.boombit.cloud/Shards/${item.tokenId}.png`}
                          />
                        </div>
                      );
                    })
                : ""}

              {sequenceWallet &&
              allitems?.equipments?.length &&
              tab === "equipments"
                ? allitems?.equipments
                    ?.filter((item) => item.name !== "Unknown")
                    .map((item, index) => {
                      console.log({ item });
                      return (
                        <div className="w-[120px] h-[120px] p-1" key={index}>
                          <img
                            className={`rounded-md w-full h-full`}
                            src={`https://hunt-nft.cdn.boombit.cloud/Equipments/${item.tokenId}.png`}
                          />
                        </div>
                      );
                    })
                : ""}

              {sequenceWallet && tab === "bgem" && (
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <img
                    src={BgemIcon}
                    alt="BGEM"
                    className="w-[120px] h-[120px]"
                  />
                  <div className="text-center mt-4">
                    <p className="text-xl font-bold">
                      {balances?.bgemBalance || "0"} BGEM
                    </p>
                  </div>
                </div>
              )}

              {sequenceWallet && tab === "boom" && (
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <img
                    src={BoomIcon}
                    alt="BOOM"
                    className="w-[120px] h-[120px]"
                  />
                  <div className="text-center mt-4">
                    <p className="text-xl font-bold">
                      {balances?.boomBalance || "0"} BOOM
                    </p>
                  </div>
                </div>
              )}

              {!allhunters?.length && tab === "hunters" && (
                <span className="text-lg text-center mt-10 w-full self-center">
                  No Hunters found.
                </span>
              )}
              {!allitems?.artifacts?.length && tab === "artifacts" && (
                <span className="text-lg text-center mt-10 w-full self-center">
                  No Items found.
                </span>
              )}
              {!allitems?.shards?.length && tab === "shards" && (
                <span className="text-lg text-center mt-10 w-full self-center">
                  No Items found.
                </span>
              )}
              {!allitems?.equipments?.length && tab === "equipments" && (
                <span className="text-lg text-center mt-10 w-full self-center">
                  No Items found.
                </span>
              )}
              {!sequenceWallet && (tab === "bgem" || tab === "boom") && (
                <span className="text-lg text-center mt-10 w-full self-center">
                  Connect your wallet to view your balance.
                </span>
              )}
            </div>
            <div className="defi-cards-bg mt-2 sm:w-[480px] sm:h-[320px] overflow-clip">
              <div className="defi-card-header flex justify-center align-items-center flex-col sm:flex-row">
                <button
                  className="px-4 py-2 rounded-lg flex flex-row justify-center hover:bg-gradient-to-r hover:from-[#9734d9] hover:to-[#f5bc0c]"
                  type="button"
                  onClick={walletClient ? openAccountModal : openConnectModal}
                >
                  <img
                    className="w-[32px] h-[32px] self-center mr-2"
                    src={sequenceLogo}
                    alt="Logo"
                  />
                  <span className="text-center self-center text-xl">
                    {walletClient
                      ? `${walletClient.account.address.slice(
                          0,
                          10
                        )}... ${walletClient.account.address.slice(
                          walletClient.account.address.length - 10,
                          walletClient.account.address
                        )}`
                      : "Connect your Sequence Wallet"}
                  </span>
                </button>
              </div>
              <div className="defi-card-header flex justify-center align-items-center flex-col sm:flex-row mt-4">
                <button
                  className="mb-2 px-4 py-2 rounded-lg flex flex-row justify-center hover:bg-gradient-to-r hover:from-[#9734d9] hover:to-[#f5bc0c]"
                  type="button"
                  onClick={address ? disconnect : connect}
                >
                  <img
                    className="w-[32px] h-[32px] self-center mr-2"
                    src={imxLogo}
                    alt="Logo"
                  />
                  <span className="text-center self-center  text-xl">
                    {address
                      ? `${address.slice(0, 10)}... ${address.slice(
                          address.length - 10,
                          address
                        )}`
                      : "Connect your Immutable Wallet"}
                  </span>
                </button>
              </div>

              <div className="flex flex-col justify-center mt-12">
                {error ? <span className="text-red-500">{error}</span> : null}

                {success ? (
                  <span className="text-green-500">
                    Congratulations! You succesfuly bridged your hunters!{" "}
                    <b>
                      They will be on your immutable wallet in up to 10 minutes,
                      please wait.
                    </b>
                  </span>
                ) : null}
              </div>
              <button
                className="mt-2 w-full self-end"
                onClick={transferHunters}
                // disabled={
                //   success || !walletClient?.account?.address || !address
                // }
              >
                <div
                  className={`${
                    success || !walletClient?.account?.address || !address
                      ? "bg-gray-500 rounded-lg p-2"
                      : "buy-btn-defi"
                  }`}
                >
                  {isLoading ? (
                    <ClipLoader css={spinnerCss} size={20} color={"#fff"} />
                  ) : (
                    "BRIDGE"
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Swap = () => (
  <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider chains={chains} theme={darkTheme()}>
      <PassportProvider>
        <SwapPage />
      </PassportProvider>
    </RainbowKitProvider>
  </WagmiConfig>
);
export default Swap;
